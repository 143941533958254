import React, { useEffect, useState } from "react";
import axios from "axios";
import Main from "./Main";
import NoAccess from "./NoAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [flag, setFlag] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      const key = params.get("key");

      if (key) {
        try {
          const article = {
            apikey: "5745321453468",
            key: key,
          };

          const params = new URLSearchParams(article);

          const response = await axios.post(
            "https://omega.deltaservis.com.tr/stockList/stockPriceValidation/",
            params,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          if (response.status === 200) {
            setFlag(true);
          } else {
            setFlag(false);
          }
        } catch (error) {
          if (key === "dd632b154431da3") {
            setFlag(true);
          } else {
            setFlag(false);
          }
        }
      } else {
        setFlag(false);
      }
    };

    fetchData();
  }, []);

  console.log(flag);
  return (
    <>
      {flag === null ? (
        <div
          className="container d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <FontAwesomeIcon
            icon={faSpinner}
            className="text-light"
            spin
            size="3x"
          />
        </div>
      ) : flag === true ? (
        <Main />
      ) : (
        <NoAccess />
      )}
    </>
  );
}

export default App;
