import React from "react";
import { Link } from "react-scroll";
import logo from "./assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faEnvelope,
  faMap,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const FooterArea = () => {
  return (
    <div id="bankalar" className="mt-5 pt-5 text-center">
      <h3 className="contact contact-title text-white my-5">
        <Link to="bankalar" smooth={true} duration={1000}>
          <strong>Bankalar & Kartlar</strong>
        </Link>
      </h3>
      <div className="footer mb-5">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="options" style={{ width: "105px" }}>
                Banka
              </th>
              <th className="options">2 Taksit</th>
              <th className="options">3 Taksit</th>
              <th className="options">4 Taksit</th>
              <th className="options">5 Taksit</th>
              <th className="options">6 Taksit</th>
              <th className="options">7 Taksit</th>
              <th className="options">8 Taksit</th>
              <th className="options">9 Taksit</th>
              <th className="options">10 Taksit</th>
              <th className="options">11 Taksit</th>
              <th className="options">12 Taksit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="banks" style={{ backgroundColor: "#f16e40" }}>
                Advantage
              </td>
              <td>%6.32</td>
              <td>%8.2</td>
              <td>%10.06</td>
              <td>%11.92</td>
              <td>%13.79</td>
              <td>%15.65</td>
              <td>%17.53</td>
              <td>%19.41</td>
              <td>%21.28</td>
              <td>%23.15</td>
              <td>%25.01</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#ffcc00" }}>
                Axess
              </td>
              <td>%6.32</td>
              <td>%8.14</td>
              <td>%9.98</td>
              <td>%11.84</td>
              <td>%13.71</td>
              <td>%15.57</td>
              <td>%17.53</td>
              <td>%19.32</td>
              <td>%21.19</td>
              <td>%23.06</td>
              <td>%24.93</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#9ccc3c" }}>
                Bonus
              </td>
              <td>%6.36</td>
              <td>%8.27</td>
              <td>%10.17</td>
              <td>%12.06</td>
              <td>%13.98</td>
              <td>%15.88</td>
              <td>%17.77</td>
              <td>%19.68</td>
              <td>%21.57</td>
              <td>%23.47</td>
              <td>%25.37</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#446fbe" }}>
                CardFinans
              </td>
              <td>%6.32</td>
              <td>%8.2</td>
              <td>%10.06</td>
              <td>%11.92</td>
              <td>%13.79</td>
              <td>%15.65</td>
              <td>%17.53</td>
              <td>%19.41</td>
              <td>%21.28</td>
              <td>%23.15</td>
              <td>%25.01</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#e2001a" }}>
                BankKart
              </td>
              <td>%6.28</td>
              <td>%8.42</td>
              <td>%10.52</td>
              <td>%12.56</td>
              <td>%14.81</td>
              <td>%16.8</td>
              <td>%19.04</td>
              <td>%21.06</td>
              <td>%23.25</td>
              <td>%25.05</td>
              <td>%27.07</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#ed4b9e" }}>
                Maximum
              </td>
              <td>%7.02</td>
              <td>%8.23</td>
              <td>%10.73</td>
              <td>%12.54</td>
              <td>%13.69</td>
              <td>%15.66</td>
              <td>%17.53</td>
              <td>%19.41</td>
              <td>%21.28</td>
              <td>%23.15</td>
              <td>%25.01</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#00a7b3" }}>
                Paraf
              </td>
              <td>%6.62</td>
              <td>%8.45</td>
              <td>%10.24</td>
              <td>%12.07</td>
              <td>%14.01</td>
              <td>%15.83</td>
              <td>%17.69</td>
              <td>%19.55</td>
              <td>%21.73</td>
              <td>%23.62</td>
              <td>%25.5</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#4db749" }}>
                Sağlam Kart
              </td>
              <td>%5.8</td>
              <td>%7.45</td>
              <td>%9.13</td>
              <td>%10.75</td>
              <td>%12.41</td>
              <td>%14.11</td>
              <td>%15.76</td>
              <td>%17.45</td>
              <td>%19.06</td>
              <td>%20.7</td>
              <td>%22.39</td>
            </tr>
            <tr>
              <td className="banks" style={{ backgroundColor: "#75348b" }}>
                World
              </td>
              <td>%6.33</td>
              <td>%8.2</td>
              <td>%10.06</td>
              <td>%11.93</td>
              <td>%13.8</td>
              <td>%15.66</td>
              <td>%17.54</td>
              <td>%19.42</td>
              <td>%21.28</td>
              <td>%23.15</td>
              <td>%25.01</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row text-light py-5" id="iletisim">
        <div className="col-12 pt-5 text-center">
          <Link to="iletisim" smooth={true} duration={1000}>
            <h2 className="contact contact-title">İletişim</h2>
          </Link>
        </div>
        <div className="col-12 p-0">
          <iframe
            title="googleMap"
            className="my-5 maps"
            width={"100%"}
            height={"400px"}
            src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.3280248479387!2d28.862069076545847!3d40.99619302016806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9ea79191589%3A0x1b9a6b7f14ff7ec3!2sDelta%20Servis!5e0!3m2!1str!2str!4v1720169225846!5m2!1str!2str"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="container-fluid">
          <div className="row pt-5">
            <div className="col-12 col-sm-3 text-center text-sm-left">
              <div>
                <a href="#" className="footer-area">
                  <img
                    alt="Delta Servis"
                    className="img-responsive logo-footer lazy"
                    src={logo}
                  />
                </a>
                <div className="pt-2">
                  © Telif Hakkı 2024. Tüm Hakları Saklıdır.
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 text-center text-sm-left">
              <Link to="urunler" smooth={true} duration={1000}>
                <strong>Markalar</strong>
              </Link>
              <div className="row">
                <div className="col-6 text-left m-auto">
                  <ul>
                    <li>
                      <Link to="Apple" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Apple</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to="Xiaomi" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Xiaomi</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to="Oppo" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Oppo</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to="Vivo" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Vivo</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to="Tecno" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Tecno</strong>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-6 text-left m-auto">
                  <ul>
                    <li>
                      <Link to="Samsung" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Samsung</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to="Huawei" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Huawei</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to="Poco" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>Poco</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to="OnePlus" smooth={true} duration={1000}>
                        <FontAwesomeIcon icon={faBarsStaggered} />
                        <strong>OnePlus</strong>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-5 contact-sub-wrapper">
              <strong className="contact-sub-title">İletişim</strong>
              <div>
                <FontAwesomeIcon icon={faEnvelope} />
                <strong className="mb-2">E-Posta:</strong>
                <a
                  className="text-light"
                  href="mailto: info@deltaservis.com.tr"
                  rel="noopener"
                >
                  info@deltaservis.com.tr
                </a>
              </div>
              <div>
                <FontAwesomeIcon icon={faPhone} />
                <strong className="mb-2">Telefon:</strong>
                <a
                  className="text-light mb-3"
                  href="tel:00908505320500"
                  rel="noopener"
                >
                  0 (850) 532 05 00
                </a>
              </div>
              <div className="d-flex">
                <strong className="mb-2 d-flex">
                  <FontAwesomeIcon icon={faMap} />
                  Adres:
                </strong>
                <a
                  href="https://www.google.com/maps/place/Delta+Servis/@40.996193,28.8620691,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab9ea79191589:0x1b9a6b7f14ff7ec3!8m2!3d40.996189!4d28.864644!16s%2Fg%2F11c5zywjq9?entry=ttu"
                  className="text-light fz-17 pt-0"
                >
                  Bahçelievler Mahallesi Mehmetçik Sokak Kadir Has Sitesi
                  No:1/1/123 Kat:5 Bahçelievler / İstanbul
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterArea;
