export const cities = [
  {
    city_id: 1,
    city_name: "Adana",
    city_code: 1,
    status: 1,
  },
  {
    city_id: 2,
    city_name: "Adıyaman",
    city_code: 2,
    status: 1,
  },
  {
    city_id: 3,
    city_name: "Afyon",
    city_code: 3,
    status: 1,
  },
  {
    city_id: 4,
    city_name: "Agrı",
    city_code: 4,
    status: 1,
  },
  {
    city_id: 5,
    city_name: "Amasya",
    city_code: 5,
    status: 1,
  },
  {
    city_id: 6,
    city_name: "Ankara",
    city_code: 6,
    status: 1,
  },
  {
    city_id: 7,
    city_name: "Antalya",
    city_code: 7,
    status: 1,
  },
  {
    city_id: 8,
    city_name: "Artvin",
    city_code: 8,
    status: 1,
  },
  {
    city_id: 9,
    city_name: "Aydın",
    city_code: 9,
    status: 1,
  },
  {
    city_id: 10,
    city_name: "Balıkesir",
    city_code: 10,
    status: 1,
  },
  {
    city_id: 11,
    city_name: "Bilecik",
    city_code: 11,
    status: 1,
  },
  {
    city_id: 12,
    city_name: "Bingöl",
    city_code: 12,
    status: 1,
  },
  {
    city_id: 13,
    city_name: "Bitlis",
    city_code: 13,
    status: 1,
  },
  {
    city_id: 14,
    city_name: "Bolu",
    city_code: 14,
    status: 1,
  },
  {
    city_id: 15,
    city_name: "Burdur",
    city_code: 15,
    status: 1,
  },
  {
    city_id: 16,
    city_name: "Bursa",
    city_code: 16,
    status: 1,
  },
  {
    city_id: 17,
    city_name: "Çanakkale",
    city_code: 17,
    status: 1,
  },
  {
    city_id: 18,
    city_name: "Çankırı",
    city_code: 18,
    status: 1,
  },
  {
    city_id: 19,
    city_name: "Çorum",
    city_code: 19,
    status: 1,
  },
  {
    city_id: 20,
    city_name: "Denizli",
    city_code: 20,
    status: 1,
  },
  {
    city_id: 21,
    city_name: "Diyarbakır",
    city_code: 21,
    status: 1,
  },
  {
    city_id: 22,
    city_name: "Edirne",
    city_code: 22,
    status: 1,
  },
  {
    city_id: 23,
    city_name: "Elazıg",
    city_code: 23,
    status: 1,
  },
  {
    city_id: 24,
    city_name: "Erzincan",
    city_code: 24,
    status: 1,
  },
  {
    city_id: 25,
    city_name: "Erzurum",
    city_code: 25,
    status: 1,
  },
  {
    city_id: 26,
    city_name: "Eskisehir",
    city_code: 26,
    status: 1,
  },
  {
    city_id: 27,
    city_name: "Gaziantep",
    city_code: 27,
    status: 1,
  },
  {
    city_id: 28,
    city_name: "Giresun",
    city_code: 28,
    status: 1,
  },
  {
    city_id: 29,
    city_name: "Gümüshane",
    city_code: 29,
    status: 1,
  },
  {
    city_id: 30,
    city_name: "Hakkari",
    city_code: 30,
    status: 1,
  },
  {
    city_id: 31,
    city_name: "Hatay",
    city_code: 31,
    status: 1,
  },
  {
    city_id: 32,
    city_name: "Isparta",
    city_code: 32,
    status: 1,
  },
  {
    city_id: 33,
    city_name: "Mersin",
    city_code: 33,
    status: 1,
  },
  {
    city_id: 34,
    city_name: "İstanbul",
    city_code: 34,
    status: 1,
  },
  {
    city_id: 35,
    city_name: "İzmir",
    city_code: 35,
    status: 1,
  },
  {
    city_id: 36,
    city_name: "Kars",
    city_code: 36,
    status: 1,
  },
  {
    city_id: 37,
    city_name: "Kastamonu",
    city_code: 37,
    status: 1,
  },
  {
    city_id: 38,
    city_name: "Kayseri",
    city_code: 38,
    status: 1,
  },
  {
    city_id: 39,
    city_name: "Kırklareli",
    city_code: 39,
    status: 1,
  },
  {
    city_id: 40,
    city_name: "Kırsehir",
    city_code: 40,
    status: 1,
  },
  {
    city_id: 41,
    city_name: "Kocaeli",
    city_code: 41,
    status: 1,
  },
  {
    city_id: 42,
    city_name: "Konya",
    city_code: 42,
    status: 1,
  },
  {
    city_id: 43,
    city_name: "Kütahya",
    city_code: 43,
    status: 1,
  },
  {
    city_id: 44,
    city_name: "Malatya",
    city_code: 44,
    status: 1,
  },
  {
    city_id: 45,
    city_name: "Manisa",
    city_code: 45,
    status: 1,
  },
  {
    city_id: 46,
    city_name: "Kahramanmaras",
    city_code: 46,
    status: 1,
  },
  {
    city_id: 47,
    city_name: "Mardin",
    city_code: 47,
    status: 1,
  },
  {
    city_id: 48,
    city_name: "Mugla",
    city_code: 48,
    status: 1,
  },
  {
    city_id: 49,
    city_name: "Mus",
    city_code: 49,
    status: 1,
  },
  {
    city_id: 50,
    city_name: "Nevsehir",
    city_code: 50,
    status: 1,
  },
  {
    city_id: 51,
    city_name: "Nigde",
    city_code: 51,
    status: 1,
  },
  {
    city_id: 52,
    city_name: "Ordu",
    city_code: 52,
    status: 1,
  },
  {
    city_id: 53,
    city_name: "Rize",
    city_code: 53,
    status: 1,
  },
  {
    city_id: 54,
    city_name: "Sakarya",
    city_code: 54,
    status: 1,
  },
  {
    city_id: 55,
    city_name: "Samsun",
    city_code: 55,
    status: 1,
  },
  {
    city_id: 56,
    city_name: "Siirt",
    city_code: 56,
    status: 1,
  },
  {
    city_id: 57,
    city_name: "Sinop",
    city_code: 57,
    status: 1,
  },
  {
    city_id: 58,
    city_name: "Sivas",
    city_code: 58,
    status: 1,
  },
  {
    city_id: 59,
    city_name: "Tekirdag",
    city_code: 59,
    status: 1,
  },
  {
    city_id: 60,
    city_name: "Tokat",
    city_code: 60,
    status: 1,
  },
  {
    city_id: 61,
    city_name: "Trabzon",
    city_code: 61,
    status: 1,
  },
  {
    city_id: 62,
    city_name: "Tunceli",
    city_code: 62,
    status: 1,
  },
  {
    city_id: 63,
    city_name: "Sanlıurfa",
    city_code: 63,
    status: 1,
  },
  {
    city_id: 64,
    city_name: "Usak",
    city_code: 64,
    status: 1,
  },
  {
    city_id: 65,
    city_name: "Van",
    city_code: 65,
    status: 1,
  },
  {
    city_id: 66,
    city_name: "Yozgat",
    city_code: 66,
    status: 1,
  },
  {
    city_id: 67,
    city_name: "Zonguldak",
    city_code: 67,
    status: 1,
  },
  {
    city_id: 68,
    city_name: "Aksaray",
    city_code: 68,
    status: 1,
  },
  {
    city_id: 69,
    city_name: "Bayburt",
    city_code: 69,
    status: 1,
  },
  {
    city_id: 70,
    city_name: "Karaman",
    city_code: 70,
    status: 1,
  },
  {
    city_id: 71,
    city_name: "Kırıkkale",
    city_code: 71,
    status: 1,
  },
  {
    city_id: 72,
    city_name: "Batman",
    city_code: 72,
    status: 1,
  },
  {
    city_id: 73,
    city_name: "Sırnak",
    city_code: 73,
    status: 1,
  },
  {
    city_id: 74,
    city_name: "Bartın",
    city_code: 74,
    status: 1,
  },
  {
    city_id: 75,
    city_name: "Ardahan",
    city_code: 75,
    status: 1,
  },
  {
    city_id: 76,
    city_name: "Igdır",
    city_code: 76,
    status: 1,
  },
  {
    city_id: 77,
    city_name: "Yalova",
    city_code: 77,
    status: 1,
  },
  {
    city_id: 78,
    city_name: "Karabük",
    city_code: 78,
    status: 1,
  },
  {
    city_id: 79,
    city_name: "Kilis",
    city_code: 79,
    status: 1,
  },
  {
    city_id: 80,
    city_name: "Osmaniye",
    city_code: 80,
    status: 1,
  },
  {
    city_id: 81,
    city_name: "Düzce",
    city_code: 81,
    status: 1,
  },
];
