import React, { useEffect, useState } from "react";
import axios from "axios";
import gsap from "gsap";
import logo from "./assets/images/logo.png";
import "./assets/css/App.css";
import ProductList from "./ProductList";
import FooterArea from "./FooterArea";
import ProductInfoTable from "./ProductInfoTable";
import { Link } from "react-scroll";
import Register from "./Register";
import Slider from "./Slider";
import InfoBox from "./BoxInfo";
import WhatsAppButton from "./WhatsAppButton";
import * as XLSX from "xlsx";

function Main() {
  const [dataA, setDataA] = useState([]);
  const [dataB, setDataB] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [responseData, setResponseData] = useState([]);

  const brandOrder = [
    "Apple",
    "Xiaomi",
    "Oppo",
    "Vivo",
    "Tecno",
    "Poco",
    "Huawei",
    "Tcl",
    "Samsung",
    "Realme",
    "OnePlus",
  ];

  const sortBrands = (data) => {
    const brandOrderMap = brandOrder.reduce((acc, brand, index) => {
      acc[brand.toLowerCase().trim()] = index; // Küçük harfe çevirme ve boşlukları temizleme
      return acc;
    }, {});

    return data.sort((a, b) => {
      const aBrand = a.brand_title.toLowerCase().trim(); // Küçük harfe çevirme ve boşlukları temizleme
      const bBrand = b.brand_title.toLowerCase().trim(); // Küçük harfe çevirme ve boşlukları temizleme

      // Eğer brand_title `brandOrder` dizisinde yoksa sona ekle
      const aIndex =
        brandOrderMap[aBrand] !== undefined ? brandOrderMap[aBrand] : Infinity;
      const bIndex =
        brandOrderMap[bBrand] !== undefined ? brandOrderMap[bBrand] : Infinity;

      return aIndex - bIndex;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://omega.deltaservis.com.tr/_api/queue/api.php?opt=salesStockPrices"
        );

        const responseData = response.data;
        setResponseData(responseData); // JSON verisi kaydedildi
        const sortedData = sortBrands(responseData);
        console.log(sortedData);
        const middlePoint = Math.ceil(sortedData.length / 2);
        console.log(sortedData);
        const first = sortedData.slice(0, middlePoint + 2);
        const secondary = sortedData.slice(middlePoint + 2);

        setDataA(first);
        setDataB(secondary);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const tl = gsap.timeline({ repeat: 1 });
    tl.to(".slideText", { backgroundPosition: "-960px 0", duration: 40 });

    fetchData();
  }, []);

  function exportToExcel() {
    const flattenedData = responseData.flatMap((brand) =>
      brand.top_models.flatMap((topModel) =>
        topModel.models.flatMap((model) =>
          model.grades.flatMap((grade) =>
            grade.variants.map((variant) => ({
              Marka: brand.brand_title,
              Model: topModel.top_model,
              Hafıza: grade.memories.new_memory,
              Kalite: grade.grade_title,
              Renk: variant.color,
              Fiyat: grade.price,
            }))
          )
        )
      )
    );

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Set column widths
    worksheet["!cols"] = [
      { width: 15 }, // Marka
      { width: 30 }, // Model
      { width: 15 }, // Model Adı
      { width: 15 }, // Kalite
      { width: 15 }, // Fiyat
      { width: 15 }, // Renk
    ];

    const header = worksheet["A1"].s || {};
    header.font = { bold: true, sz: 12, color: "FFFFFF" };
    header.fill = {
      fgColor: { rgb: "000000" },
    };

    for (let i = 0; i < flattenedData.length; i++) {
      for (const key in flattenedData[i]) {
        const cell =
          worksheet[
            XLSX.utils.encode_cell({
              r: i + 1,
              c: Object.keys(flattenedData[i]).indexOf(key),
            })
          ];
        if (cell) {
          cell.s = {
            font: { sz: 10 },
          };
        }
      }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const today = new Date();
    const dateString = today.toISOString().split("T")[0];
    XLSX.writeFile(workbook, `salesStockPrices_${dateString}.xlsx`);
  }

  return (
    <>
      <Slider />
      <div
        id="main"
        className="container-fluid custom-container mobile-p-0 mobile-m-0"
      >
        <div className="header">
          <div
            className="d-flex header-fixed"
            style={{ justifyContent: "space-evenly" }}
          >
            <div className="row w-100 align-items-center">
              <div className="col-12 col-md-2 text-left">
                <Link to="main" smooth={true} duration={1000}>
                  <img
                    className="w-100"
                    style={{ maxWidth: "250px" }}
                    src={logo}
                    alt="Delta Logo"
                  />
                </Link>
              </div>
              <div className="col-12 col-md-10 d-flex justify-content-end p-0 m-0">
                <Link
                  className="dealder-area m-auto"
                  to="bayi-ol"
                  smooth={true}
                  duration={1000}
                >
                  <h2 className="register-form-link title slideText">
                    Bayimiz Olur Musun?
                  </h2>
                </Link>
                <div className="contact-wrapper">
                  {responseData.length ? (
                    <h2
                      className="contact contact-spec mx-1"
                      onClick={() => exportToExcel()}
                    >
                      Fiyat Listesi İndir
                    </h2>
                  ) : (
                    ""
                  )}
                  <Link to="standartlar" smooth={true} duration={1000}>
                    <h2 className="contact mx-1 d-md-block d-none">
                      Kalite Standartları
                    </h2>
                  </Link>
                  <Link to="bankalar" smooth={true} duration={1000}>
                    <h2 className="contact mx-1 d-md-block d-none">
                      Banka Oranları
                    </h2>
                  </Link>
                  <Link to="iletisim" smooth={true} duration={1000}>
                    <h2 className="contact d-md-block d-none">İletişim</h2>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card-custom box1">
              <div className="m-auto">
                <h2 className="title-2 d-block">12 Ay Taksit</h2>
                <h2 className="title-2 d-block">İmkanı</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-custom box2">
              <div className="m-auto">
                <h2 className="title-2 d-block">Tek Çekim</h2>
                <h2 className="title-2 d-block">0 Komisyon</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-custom box3">
              <div className="m-auto">
                <h2 className="title-2 d-block">Ücretsiz</h2>
                <h2 className="title-2 d-block">Kargo</h2>
              </div>
            </div>
          </div>
        </div>
        {dataA && dataB ? (
          <div className="row pt-5 m-0" id="urunler">
            <div className="col-md-6 product-list pt-5 mobile-p-0 mobile-m-0">
              <ProductList
                data={dataA}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
              />
            </div>
            <div className="col-md-6 product-list pt-5 mobile-p-0 mobile-m-0">
              <ProductList
                data={dataB}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
              />
            </div>
          </div>
        ) : (
          <div className="text-danger text-center my-3">
            Stok bilgisi bulunamadı! Lütfen daha sonra tekrar deneyiniz.
          </div>
        )}
        <ProductInfoTable />
        <InfoBox />
        <Register />
        <FooterArea />
        <div className="excel-wrapper" onClick={() => exportToExcel()}>
          <svg
            className="svg-excel"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z" />
          </svg>
        </div>
        <WhatsAppButton />
      </div>
    </>
  );
}

export default Main;
