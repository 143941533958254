import React from "react";
import "./assets/css/NoAccess.css";
import noAccessImage from "./assets/images/noAccessImage.png";

function NoAccess() {
  return (
    <div className="fullpage">
      <div className="fullpage-text">
        <img className="noAccessImage" alt="noAccess" src={noAccessImage}></img>
        <p>
          Değerli iş ortağımız, sektöre özel bir sayfa olduğu için fiyat bilgisi
          güvenliği nedeniyle link 24 saatte bir yenilenmektedir.
        </p>
        <p> Güncel linke ulaşmak için Satış Temsilciniz ile iletişime geçiniz.</p>
      </div>
    </div>
  );
}

export default NoAccess;
