import React from "react";
import video from "./assets/videos/banner/banner.mp4";

export default function Slider() {
  return (
    <div className="banner-container">
      <div className="video-section">
        <video className="fullscreen-video" autoPlay loop playsInline muted>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
