import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlus,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";

const ProductInfoTable = () => {
  return (
    <div id="standartlar" className="pt-5 my-5 d-md-block d-none">
      <div className="text-center my-5 pt-5">
        <h3 className="contact contact-title text-white my-5">
          <Link to="standartlar" smooth={true} duration={1000}>
            <strong>Kalite Standartları</strong>
          </Link>
        </h3>
        <table className="table table-standart">
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Kozmetik Durum</th>
              <th style={{ width: "35%" }}>Seviye Açıklaması</th>
              <th style={{ width: "30%" }}>Test / Onay İçeriği</th>
              <th style={{ width: "20%" }}>Kutu İçeriği</th>
            </tr>
          </thead>
        </table>
        <table className="table table-standart alert-good">
          <tbody>
            <tr>
              <td style={{ width: "15%" }}>
                <strong className="d-block">A Kalite</strong>
                <hr className="hr-spec"></hr>
                <strong className="d-block">Mükemmel</strong>
              </td>
              <td className="text-justify" style={{ width: "35%" }}>
                <FontAwesomeIcon icon={faCheckDouble} />
                Ürünün ekran ya da kasasında hiçbir deformasyon bulunmamaktadır.
                Mükemmel olarak sınıflandırılmış bazı ürünlerin ekran ya da
                kasasında, gözle tespit etmesi zor, belirgin olmayan çok hafif
                izlere rastlanabilir. Cihazın tüm fonksiyonları çalışmaktadır.
              </td>
              <td style={{ width: "30%" }}>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Pil Sağlığı %85 ve üzeri
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Bilinmeyen uyarısı yok
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Parmak izi ve yüz tanıma aktif
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faCheck} />1 Sene Delta Servis
                    garantisi
                  </li>
                </ul>
              </td>
              <td style={{ width: "20%" }}>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Sim kart açma iğnesi
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Lisanslı şarj aleti kablosu
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Şarj adaptörü
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Garanti belgesi
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Yenilenmiş Ürün Sertifikası
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-standart alert-very-good">
          <tbody>
            <tr>
              <td style={{ width: "15%" }}>
                <div>
                  <strong className="d-block">B Kalite</strong>
                  <hr className="hr-spec"></hr>
                  <strong className="d-block">Çok İyi</strong>
                </div>
              </td>
              <td className="text-justify" style={{ width: "35%" }}>
                <FontAwesomeIcon icon={faCheckDouble} />
                Ekran ya da kasada, ürünün işlevselliğini ve çalışma
                performansını etkilemeyecek şekilde hafif çizikler görülebilir.
                Cihazın tüm fonksiyonları çalışmaktadır.
              </td>
              <td style={{ width: "30%" }}>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Pil Sağlığı %85 ve üzeri
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Bilinmeyen uyarısı yok
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Parmak izi ve yüz tanıma aktif
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faCheck} />1 Sene Delta Servis
                    garantisi
                  </li>
                </ul>
              </td>
              <td style={{ width: "20%" }}>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Sim kart açma iğnesi
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Lisanslı şarj aleti kablosu
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Şarj adaptörü
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Garanti belgesi
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Yenilenmiş Ürün Sertifikası
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table table-standart alert-perfect">
          <tbody>
            <tr>
              <td style={{ width: "15%" }}>
                <strong className="d-block">C Kalite</strong>
                <hr className="hr-spec"></hr>
                <strong className="d-block">İyi</strong>
              </td>
              <td className="text-justify" style={{ width: "35%" }}>
                <FontAwesomeIcon icon={faCheckDouble} />
                Ekran ya da kasada, ürünün işlevselliğini ve çalışma
                performansını etkilemeyecek şekilde, gözle görülen hafif
                çizikler bulunabilir. Cihazın tüm fonksiyonları çalışmaktadır.
              </td>
              <td style={{ width: "30%" }}>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Pil Sağlığı %85 ve üzeri
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} />
                    Parmak izi ve yüz tanıma aktif
                  </li>

                  <li>
                    <FontAwesomeIcon icon={faCheck} />1 Sene Delta Servis
                    garantisi
                  </li>
                </ul>
              </td>
              <td style={{ width: "20%" }}>
                <ul>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Sim kart açma iğnesi
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Lisanslı şarj aleti kablosu
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Şarj adaptörü
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Garanti belgesi
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faPlus} />
                    Yenilenmiş Ürün Sertifikası
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductInfoTable;
